import DateFilters from '@/filters/dateFilters'
import CurrencyFilters from '@/filters/currencyFilters'
import CaseFilters from '@/filters/caseFilters'

const Filters = {
  date: DateFilters,
  currency: CurrencyFilters,
  case: CaseFilters,
}

export default Filters
