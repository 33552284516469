import axiosIns from '@axios'

export const createUser = async (payload: any) => {
  const { data } = await axiosIns.post(`admin/users/create`, payload)
  return data?.data
}

export const updateCustomerUser = async (id: number, payload: any) => {
  const { data } = await axiosIns.put(`admin/users/${id}`, payload)
  return data?.data
}

export const updateCustomerPassword = async (uuid: string, payload: any) => {
  const { data } = await axiosIns.patch(
    `admin/users/update-password/${uuid}`,
    payload,
  )
  return data?.data
}

export const updateUser = async (user: any) => {
  const { data } = await axiosIns.put(`/user`, {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    country: user.country,
    describeAs: user.describeAs,
    phoneNumber: user.phoneNumber,
  })
  return data?.data
}

export const getAllUsers = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.get(`admin/users/all`)
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const getSearchedUserByType = (
  query: string,
  type: string,
  whiteLabelId: number,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.get(
        `admin/users/search/${query}/isAdmin/${type}/whiteLabelId/${whiteLabelId}`,
      )
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const getSearchedUserByEmailOrName = (query: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.post(
        `admin/users/search/name-or-email/`,
        {
          query: query,
        },
      )
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const getUsersByFilter = (payload: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.post(`admin/users/filters`, payload)
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const updateUserStatus = (id: number, status: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await axiosIns.patch(
        `admin/users/update/${id}/status/${status}`,
      )
      resolve(data?.data)
    } catch (e) {
      reject(e)
    }
  })
}

export const getUser = async () => {
  const { data } = await axiosIns.get('/user')
  return data?.data
}
