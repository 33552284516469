<script setup>
import VueApexCharts from 'vue3-apexcharts'
import { useTheme } from 'vuetify'
import { getLineChartSimpleConfig } from '@core/libs/apex-chart/apexCharConfig'
import { useDashboardStore } from '@/stores/dashboard'
import { useRootStore } from '@/stores/root'
import { ref } from 'vue'
import _ from 'lodash'

const dashboardStore = useDashboardStore()
const rootStore = useRootStore()
const lineColors = [
  '#8B1874',
  '#D14D72',
  '#9E6F21',
  '#FFA559',
  '#6DA9E4',
  '#FF6000',
  '#159895',
  '#8F43EE',
]
const vuetifyTheme = useTheme()
const balanceChartConfig = computed(() => {
  const current = computed(() => vuetifyTheme.current.value)
  const config = getLineChartSimpleConfig(current.value)
  return config
})
const isLoading = ref(false)

const series = ref([])

const getData = async () => {
  isLoading.value = true
  try {
    const data = await dashboardStore.getWPStatsGraph()
    if (data) {
      balanceChartConfig.value.xaxis.categories = data.categories
      balanceChartConfig.value.tooltip = {
        enabled: true,
        y: {
          title: {
            formatter: function (val) {
              const key = series.value.find((item) => item.name === val)?.key
              return key
                ? '# of sites with ' + simpleKeyToTitle(key) + ':'
                : val
            },
          },
          formatter: function (val) {
            return `
            <span class="ml-2 font-weight-bold" style="font-size: 14px">${val}</span>
            `
          },
        },
      }
      series.value = data?.data
    }
  } catch (e) {
    rootStore.showSnackbar(
      'Error fetching PSI stats graph data, Please contact support!',
      'error',
    )
  } finally {
    isLoading.value = false
  }
}
const keyToTitle = (key) => {
  return `${key.split('.')[0].toUpperCase()} - ${key
    .split('.')[2]
    .split('_')
    .map((word) => _.capitalize(word))
    .join(' ')} ${_.capitalize(key?.split('.')[1]?.split('_')[0])} ${
    key?.split('.')[2] === 'page_speed'
      ? key?.split('.')[0] === 'mobile'
        ? 80
        : 85
      : key?.split('.')[0] === 'mobile'
      ? '3.5 seconds'
      : '3 seconds'
  }
`
}
const simpleKeyToTitle = (key) => {
  return `${key.split('.')[0]} ${key
    .split('.')[2]
    .split('_')
    .map((word) => word)
    .join(' ')} ${key?.split('.')[1]?.split('_')[0]} ${
    key?.split('.')[2] === 'page_speed'
      ? key?.split('.')[0] === 'mobile'
        ? 80
        : 85
      : key?.split('.')[0] === 'mobile'
      ? '3.5 seconds'
      : '3 seconds'
  }`
}
onMounted(() => {
  getData()
})
</script>
<template>
  <VCol cols="12">
    <VCard class="py-3">
      <VCardItem class="d-flex flex-wrap justify-space-between">
        <VCardTitle>PSI Stats Graphs</VCardTitle>
      </VCardItem>
      <VDivider />
      <VCardText v-if="!isLoading">
        <VRow>
          <VCol
            v-for="(singleSeries, index) in series"
            xs="12"
            sm="12"
            md="6"
            lg="6"
          >
            <VCard
              :style="'border-top: 2px solid' + lineColors[index]"
              elevation="5"
            >
              <span
                :style="'color: ' + lineColors[index]"
                class="pl-4 text-lg-caption px-auto"
              >
                <span
                  style="
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    display: inline-block;
                  "
                  :style="'background-color: ' + lineColors[index]"
                />
                {{ keyToTitle(singleSeries.key) }}
              </span>
              <VueApexCharts
                :key="singleSeries.key"
                type="line"
                height="400"
                :options="{
                  ...balanceChartConfig,
                  colors: [lineColors[index]],
                }"
                :series="[singleSeries]"
              />
            </VCard>
          </VCol>
        </VRow>
      </VCardText>
      <section v-else style="min-height: 300px" class="text-center mt-4">
        <VProgressCircular indeterminate size="100" />
      </section>
    </VCard>
  </VCol>
</template>
